import {LoadingDots} from '@commercial-helios/ui/src/components/loading-dots'
import {tokens, colors, radii} from '@commercial-helios/ui/theme'
import React from 'react'
import styled, {css} from 'styled-components'

import {text} from '@helios/shared/components/text'
import {focusStyles} from '@helios/shared/styles/utils'

type ButtonVariant = keyof Omit<typeof colors.button, 'disabled'>

export interface Props extends Omit<React.HTMLProps<HTMLButtonElement>, 'data'> {
    $variant?: ButtonVariant
    $loading?: boolean
    'data-testid'?: string
    as?: any
    forwardedAs?: any
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
    (
        {as: ButtonComponent = 'button', forwardedAs, children, $loading, disabled, ...props},
        ref,
    ) => (
        <ButtonComponent {...props} as={forwardedAs} disabled={disabled || $loading} ref={ref}>
            {$loading && <LoadingDots />}

            <Children $loading={$loading}>{children}</Children>
        </ButtonComponent>
    ),
)

interface ChildrenProps {
    $loading?: boolean
}

const Children = styled.span<ChildrenProps>`
    visibility: ${(props) => props.$loading && 'hidden'};
`

const StyledButton = styled(Button)<Props>(({$variant = 'default'}) => {
    const spread = $variant === 'inverted' ? 'thick' : 'thin'
    return css`
        ${text({$variant: 'spezia'})}
        position: relative;
        display: inline-flex;
        padding: ${tokens.spacing14} ${tokens.spacing28};
        line-height: 1;
        color: ${colors.button[$variant].foreground};
        white-space: nowrap;
        cursor: pointer;
        background: ${colors.button[$variant].background};
        border: 1px solid ${colors.button[$variant].border};
        border-radius: ${radii.circle};
        justify-content: center;
        align-items: center;

        &:hover,
        &:active {
            color: ${colors.button[$variant].hover.foreground};
            background: ${colors.button[$variant].hover.background};
            border-color: ${colors.button[$variant].hover.border};
        }

        &:focus-visible {
            ${focusStyles(spread)}
        }

        &:disabled {
            color: ${colors.button.disabled.foreground};
            cursor: not-allowed;
            background: ${colors.button.disabled.background};
            border-color: ${colors.button.disabled.border};
        }
    `
})

export default StyledButton
