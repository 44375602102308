import React from 'react'

import {useRecaptcha} from '@helios/shared/hooks/use-recaptcha'
import type {ConsentsPayload} from '@helios/shared/types/consents'
import {ConsentState, updateConsentsState} from '@helios/shared/utils/google-ads-consents'
import type {HubspotState} from '@helios/shared/utils/submit-hubspot-form'
import {submitHubspotForm} from '@helios/shared/utils/submit-hubspot-form'

import {useMappedHubspotPayload} from './use-mapped-hubspot-payload'

/**
 * This hook handles the common flow of executing reCAPTCHA, calling the Hubspot
 * API (with the reCAPTCHA token), and managing the loading state
 */
export const useSubmitHubspotForm = (formId: string, hasSignedUp: boolean) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSubmitted, setIsSubmitted] = React.useState(false)
    const captcha = useRecaptcha('hubspot_submit_form')
    const getHubspotPayload = useMappedHubspotPayload()

    React.useEffect(() => {
        setIsSubmitted(hasSignedUp)
    }, [hasSignedUp])

    const recaptchaHubspotSubmit = async (
        hubspotState: HubspotState,
        consent?: ConsentsPayload,
        onError?: () => void,
        onSuccess?: () => void,
        onBeforeSubmit?: () => Promise<object> | undefined,
    ) => {
        try {
            setIsLoading(true)
            updateConsentsState(ConsentState.Granted)

            const additionalProperties = onBeforeSubmit ? await onBeforeSubmit() : {}

            const response = await captcha?.protectedCall(
                async (captchaToken) =>
                    await submitHubspotForm(
                        captchaToken,
                        getHubspotPayload({...hubspotState, ...additionalProperties}),
                        formId,
                        consent,
                    ),
            )

            if (response !== 'error') {
                setIsSubmitted(true)
                onSuccess && onSuccess()
            }
        } catch (error) {
            onError && onError()
            setIsSubmitted(false)
        } finally {
            setIsLoading(false)
        }
    }

    return {recaptchaHubspotSubmit, isLoading, isSubmitted}
}
