import {VisuallyHidden} from '@commercial-helios/ui/src/components/visually-hidden'
import {radii, tokens} from '@commercial-helios/ui/theme'
import React from 'react'
import styled from 'styled-components'

import {focusStyles} from '@helios/shared/styles/utils'

interface SwitchProps {
    checked: boolean
    label?: string
    onChange: (checked: boolean) => void
}

export const Switch = ({checked, label, onChange}: SwitchProps) => (
    <Wrapper checked={checked}>
        <VisuallyHidden>
            {label}
            <input type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
        </VisuallyHidden>

        <Toggle checked={checked} />
    </Wrapper>
)

const wrapperWidth = 34
const toggleSize = 18
const padding = 1

type CheckedProps = Pick<SwitchProps, 'checked'>

const Wrapper = styled.label<CheckedProps>`
    display: flex;
    width: ${wrapperWidth}px;
    padding: ${padding}px;
    cursor: pointer;
    background-color: ${(props) => (props.checked ? tokens.green500 : tokens.shade500)};
    border-radius: ${radii.circle};
    transition: background-color ${tokens.smoothInOut};

    &:focus-within {
        ${focusStyles()}
    }
`

const Toggle = styled.div<CheckedProps>`
    width: ${toggleSize}px;
    height: ${toggleSize}px;
    margin-left: ${(props) => (props.checked ? wrapperWidth - toggleSize - padding * 2 : 0)}px;
    background-color: ${tokens.shade000};
    border-radius: ${radii.circle};
    transition-duration: inherit;
    transition-property: margin-left;
`

export default Switch
