import React from 'react'

import {Text} from '@helios/shared/components/text'
import type {TextProps} from '@helios/shared/components/text'
import {useGlobalStrings} from '@helios/shared/providers/global-strings'
import type {GlobalStrings} from '@helios/shared/types/global-strings'

interface Props extends TextProps {
    globalStringKey: keyof GlobalStrings
}

export const GlobalText = ({globalStringKey, ...props}: Props) => {
    const globalStrings = useGlobalStrings()

    const text = globalStrings[globalStringKey] as string

    return text ? <Text {...props}>{text}</Text> : null
}
