import {graphql, useStaticQuery} from 'gatsby'
import React from 'react'

import type {CookieBannerQuery} from '@/graphql-types'
import FooterBannerComponent from '@helios/shared/components/footer-banner'
import {useLocalizedContent} from '@helios/shared/hooks/use-localized-content'

export const FooterBanner = () => {
    const {allPrismicCookieBanner} = useStaticQuery<CookieBannerQuery>(query)
    const [content] = useLocalizedContent(allPrismicCookieBanner.nodes)

    return (
        <FooterBannerComponent
            content={{
                ...content.data,
                banner_body: content.data.banner_body.richText,
                banner_button_accept: content.data.banner_button_accept.text,
                manage_cookies_modal_link: content.data.manage_cookies_modal_link.text,
                modal_title: content.data.modal_title.text,
                ads_disclaimer: content.data.ads_disclaimer.richText,
                necessary_cookies_disclaimer: content.data.necessary_cookies_disclaimer.richText,
            }}
        />
    )
}

const query = graphql`
    query CookieBanner {
        allPrismicCookieBanner {
            nodes {
                _previewable
                lang
                data {
                    title
                    body {
                        richText
                    }
                    dismiss_text
                    banner_body {
                        richText
                    }
                    banner_button_accept {
                        text
                    }
                    manage_cookies_modal_link {
                        text
                    }
                    modal_title {
                        text
                    }
                    ads_disclaimer {
                        richText
                    }
                    necessary_cookies_label
                    always_on_label
                    necessary_cookies_disclaimer {
                        richText
                    }
                    modal_cancel_button
                    modal_save_changes_button
                    modal_image {
                        dimensions {
                            height
                            width
                        }
                        alt
                        copyright
                        url
                    }
                    tracking_categories
                }
            }
        }
    }
`
