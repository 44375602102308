import type {ReactNode} from 'react'
import React from 'react'

import type {PageProps} from '@helios/shared/types/page-props'

type ContextProps = Omit<PageProps, 'children'>

const PageInfoContext = React.createContext<ContextProps>({
    pageContext: {},
} as ContextProps)

/* The property 'children' in PageProps is of type 'undefined'.
For this component, we need this property to be of type ReactNode. 
So we first strip off the 'children' from PageProps and add it again with type ReactNode. */
export type Props = Omit<PageProps, 'children'> & {children: ReactNode}

export const PageInfoProvider = ({children, ...props}: Props) => (
    <PageInfoContext.Provider value={props}>{children}</PageInfoContext.Provider>
)

export const usePageInfo = () => React.useContext(PageInfoContext)
