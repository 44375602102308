import Cookies from 'js-cookie'
import ky from 'ky'
import type {CountryCode} from 'libphonenumber-js'

import {paramCookiesMap} from '@helios/shared/config/cookies'
import type CompanySize from '@helios/shared/types/company-size'
import type {ConsentsPayload} from '@helios/shared/types/consents'
import type DiscoveredVia from '@helios/shared/types/discovered-via'
import type {HubspotContactProps} from '@helios/shared/types/hubspot-contact-props'

export interface HubspotState {
    firstName?: string
    lastName?: string
    email: string
    genericEmail: boolean
    phoneNumber?: string
    phoneCountryCode?: CountryCode
    company?: string
    companySize?: CompanySize | ''
    country?: HubspotContactProps['country']
    discoveredVia?: DiscoveredVia | ''
    hasConsented?: boolean
    marketingEmails?: boolean
    lifecyclestage?: HubspotContactProps['lifecyclestage']
    event_name?: HubspotContactProps['event_name']
    latest_campaign?: HubspotContactProps['latest_campaign']
    lead_source_latest_capture_type?: HubspotContactProps['lead_source_latest_capture_type']
    pleo_partner_id?: HubspotContactProps['pleo_partner_id']
    partner_channel?: HubspotContactProps['partner_channel']
    type_of_influence?: HubspotContactProps['type_of_influence']
    partner_segment?: HubspotContactProps['partner_segment']
    partnership_type?: HubspotContactProps['partnership_type']
    partnership_referred_date?: HubspotContactProps['partnership_referred_date']
    type_of_lead?: HubspotContactProps['type_of_lead']
    lead_interest_level?: HubspotContactProps['lead_interest_level']
    message?: HubspotContactProps['message']
    pdfUrl?: string
    associated_partner_id?: HubspotContactProps['associated_partner_id']
}
interface Field {
    objectTypeId: '0-1' | '0-2' | '0-3' | '0-5'
    name: keyof HubspotContactProps
    value?: string
}

export interface HubspotFormData {
    context: {
        hutk?: string
        ipAddress?: string
        pageUri: string
        pageName: string
    }
    fields: Array<Field>
    formId: string
}

export const submitHubspotForm = async (
    captchaResponse: string,
    state: HubspotState,
    formId: string,
    consent?: ConsentsPayload,
) => {
    const consents = {
        legalConsentOptions: {
            consent,
        },
    }

    const hutk = Cookies.get(paramCookiesMap.hubspotToken)

    const formData: HubspotFormData = {
        fields: [
            {
                objectTypeId: '0-1',
                name: 'email',
                value: state?.email || '',
            },
            {
                objectTypeId: '0-1',
                name: 'firstname',
                value: state?.firstName || '',
            },
            {
                objectTypeId: '0-1',
                name: 'lastname',
                value: state?.lastName || '',
            },
            {
                objectTypeId: '0-1',
                name: 'phone',
                value: state?.phoneNumber || '',
            },
            {
                objectTypeId: '0-1',
                name: 'country',
                value: state?.country || '',
            },
            {
                objectTypeId: '0-1',
                name: 'company_size_range',
                value: state?.companySize || '',
            },
            {
                objectTypeId: '0-1',
                name: 'how_did_you_hear_about_us_',
                value: state?.discoveredVia || '',
            },
            {
                objectTypeId: '0-1',
                name: 'company',
                value: state?.company || '',
            },
            {
                objectTypeId: '0-1',
                name: 'hs_legal_basis',
                value: state?.marketingEmails ? 'Freely given consent from contact' : '',
            },
            {
                objectTypeId: '0-1',
                name: 'utm_source',
                value: Cookies.get(paramCookiesMap.utmSource) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'utm_medium',
                value: Cookies.get(paramCookiesMap.utmMedium) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'utm_campaign',
                value: Cookies.get(paramCookiesMap.utmCampaign) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'utm_term',
                value: Cookies.get(paramCookiesMap.utmTerm) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'utm_content',
                value: Cookies.get(paramCookiesMap.utmContent) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'latest_utm_source',
                value: Cookies.get(paramCookiesMap.latestUtmSource) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'latest_utm_medium',
                value: Cookies.get(paramCookiesMap.latestUtmMedium) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'latest_utm_campaign',
                value: Cookies.get(paramCookiesMap.latestUtmCampaign) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'latest_utm_term',
                value: Cookies.get(paramCookiesMap.latestUtmTerm) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'latest_utm_content',
                value: Cookies.get(paramCookiesMap.latestUtmContent) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'referral_id',
                value: Cookies.get(paramCookiesMap.referralCode) || '',
            },
            {
                objectTypeId: '0-1',
                name: 'lifecyclestage',
                value: state?.lifecyclestage || '',
            },
            {
                objectTypeId: '0-1',
                name: 'event_name',
                value: state?.event_name || '',
            },
            {
                objectTypeId: '0-1',
                name: 'latest_campaign',
                value: state?.latest_campaign || '',
            },
            {
                objectTypeId: '0-1',
                name: 'lead_source_latest_capture_type',
                value: state?.lead_source_latest_capture_type || '',
            },
            {
                objectTypeId: '0-1',
                name: 'pleo_partner_id',
                value: state?.pleo_partner_id || '',
            },
            {
                objectTypeId: '0-1',
                name: 'associated_partner_id',
                value: state?.associated_partner_id || '',
            },
            {
                objectTypeId: '0-1',
                name: 'partner_channel',
                value: state?.partner_channel || '',
            },
            {
                objectTypeId: '0-1',
                name: 'type_of_influence',
                value: state?.type_of_influence || '',
            },
            {
                objectTypeId: '0-1',
                name: 'partner_segment',
                value: state?.partner_segment || '',
            },
            {
                objectTypeId: '0-1',
                name: 'partnership_type',
                value: state?.partnership_type || '',
            },
            {
                objectTypeId: '0-1',
                name: 'partnership_referred_date',
                value: state?.partnership_referred_date || '',
            },
            {
                objectTypeId: '0-1',
                name: 'type_of_lead',
                value: state?.type_of_lead || '',
            },
            {
                objectTypeId: '0-1',
                name: 'lead_interest_level',
                value: state?.lead_interest_level || '',
            },
            {
                objectTypeId: '0-1',
                name: 'message',
                value: state?.message || '',
            },
            {
                objectTypeId: '0-1',
                name: 'pdf_url',
                value: state?.pdfUrl || '',
            },
        ],
        context: {
            hutk,
            pageUri: window?.location.href || '',
            pageName: document?.title || '',
        },
        formId,
        ...(consent && consents),
    }

    return await ky
        .post('/api/hubspot-submit-form', {
            json: {formData, captchaResponse},
            throwHttpErrors: true,
        })
        .json()
}
