import React from 'react'

import {getPageSplitExperimentPaths} from '@helios/shared/utils/get-experiments'

/**
 * Calling this hook returns an array of path regex patterns for every currently
 * running page split experiment
 */
export const usePageSplitExperimentPaths = () => {
    const [experimentPaths, setExperimentPaths] = React.useState<string[] | null>(null)

    React.useEffect(() => {
        setExperimentPaths(getPageSplitExperimentPaths())
    }, [])

    return experimentPaths
}
