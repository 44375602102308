// workaround for the Gatsby/Storybook bug - https://github.com/storybookjs/storybook/issues/17176
import GatsbyLink from 'gatsby-link'
import React from 'react'

import type {StateProps} from '@helios/shared/hooks/use-navigate'
import {usePageSplitExperimentPaths} from '@helios/shared/hooks/use-page-split-experiment-paths'
import type {UrlCode} from '@helios/shared/locale/locales'
import {useLocale} from '@helios/shared/providers/locale'
import {removeTrailingSlash} from '@helios/shared/utils/remove-trailing-slash'

type AnchorProps = JSX.IntrinsicElements['a'] & {
    referrerPolicy?: ReferrerPolicy
}

type Props = Omit<AnchorProps, 'href' | 'css'> & {
    to: string
    rawPath?: boolean
    locale?: UrlCode
    state?: StateProps
}

const LocalizedLink = React.forwardRef<HTMLAnchorElement, Props>(
    ({children, to, locale, rawPath, ...props}, ref) => {
        const currentLocale = useLocale()
        const urlLocaleCode = locale ?? currentLocale.urlCode
        const destinationPath = rawPath ? to : `/${urlLocaleCode}${to}`

        // Get all paths (regex patterns) that have experiments on
        const experimentPaths = usePageSplitExperimentPaths()
        // Check if the destination path has an experiment running on it
        const destinationPathHasExperiment = experimentPaths?.some((experimentPath) =>
            new RegExp(experimentPath).test(destinationPath),
        )

        // If the destination path has an experiment running on it, we don't
        // want to use a GatsbyLink since it uses JS navigation and the request
        // doesn't go through the Lambdas. We need to use a regular anchor tag
        // for pages with experiments to cause a page refresh which sends the
        // request through to the Lambdas which can then run the experiment
        // logic to determine where the user should end up
        if (process.env.FRAMEWORK === 'gatsby' && !destinationPathHasExperiment) {
            return (
                <GatsbyLink ref={ref as any} {...props} to={removeTrailingSlash(destinationPath)}>
                    {children}
                </GatsbyLink>
            )
        }

        return (
            <a href={destinationPath} {...props} ref={ref}>
                {children}
            </a>
        )
    },
)

export default LocalizedLink
