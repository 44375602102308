import React from 'react'

import {isBrowser} from '@helios/shared/utils/browser'

type SetValue<T> = React.Dispatch<React.SetStateAction<T>>

/**
 *
 * @param key The key of the data to be stored in the storage
 * @param initialValue optional initial value to assign storage
 * @param storage: Storage | undefined: The storage is undefined when it is executed on the server
 * @desc: This hook abstracts the sessionStorage and the localStorage API to
 * access from and set data to sessionStorage and localStorage
 */
const useStorage = <T extends any>(
    key: string,
    initialValue: T,
    storage: Storage | undefined,
): [T, SetValue<T>] => {
    // Get from storage then parse stored json or return initialValue
    const readValue = React.useCallback((): T => {
        if (!isBrowser || !storage) {
            return initialValue
        }

        try {
            const item = storage.getItem(key)
            return item ? (parseJSON(item) as T) : initialValue
        } catch (error) {
            console.warn(`Error reading ${storage} key “${key}”:`, error)
            return initialValue
        }
    }, [key, initialValue, storage])

    const [state, setState] = React.useState<T>(readValue)

    const setValue: SetValue<T> = (value) => {
        try {
            storage?.setItem(key, JSON.stringify(value))
            setState(value)
        } catch (error) {
            console.warn(`Error setting ${storage} key “${key}”:`, error)
        }
    }

    return [state, setValue]
}

// A wrapper for "JSON.parse()"" to support "undefined" value
const parseJSON = <T,>(value: string | null): T | undefined => {
    try {
        return value === 'undefined' ? undefined : JSON.parse(value ?? '')
    } catch {
        console.warn('parsing error on', {value})
        return undefined
    }
}

export const useLocalStorage = <T extends any>(key: string, initialValue: T) =>
    useStorage<T>(key, initialValue, isBrowser ? localStorage : undefined)

export const useSessionStorage = <T extends any>(key: string, initialValue: T) =>
    useStorage<T>(key, initialValue, isBrowser ? sessionStorage : undefined)
