import {getRawEncounteredExperiments, getRawExperiments} from '@helios/shared/utils/get-experiments'

const createTrackingForExperiments = () => {
    const rawExperiments = getRawExperiments() ?? []

    /**
     * Creates an object with each experiment as a separate property, e.g.
     * {
     *   "split-test-bigform": "new-bigform",
     *   "split-test-ebooks": "old-ebooks"
     * }
     */
    const experiments = Object.fromEntries(
        rawExperiments.map(([experimentName, experimentValue]) => [
            `split-test-${experimentName}`,
            experimentValue,
        ]),
    )

    const rawEncounteredExperiments = getRawEncounteredExperiments() ?? []

    /**
     * Creates an object with each experiment as a separate property, e.g.
     * {
     *   "split-test-encountered-bigform": "on",
     *   "split-test-encountered-ebooks": "off"
     * }
     */
    const encounteredExperiments = Object.fromEntries(
        rawEncounteredExperiments.map(([experimentName, experimentValue]) => [
            `split-test-encountered-${experimentName}`,
            experimentValue,
        ]),
    )

    return {
        ...experiments,
        ...encounteredExperiments,
        'user-encountered-experiment': Object.values(encounteredExperiments).some(
            (value) => value === 'on',
        ),
    }
}

export default createTrackingForExperiments
