import {tokens, colors} from '@commercial-helios/ui/theme'
import {graphql, useStaticQuery} from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import type {DefaultSignupModalQuery} from '@/graphql-types'
import {useEmailValidationErrorMessage} from '@/hooks/use-email-validation-error-message'
import useSignup from '@/hooks/use-signup'
import type {Args} from '@/hooks/use-signup'
import {CheckmarkList} from '@/styles/lists'
import hasCookieExperiment from '@/utils/has-cookie-experiment'
import {
    signupFormModalOpened,
    SignupFormModalOpenedAction,
    signupFormSubmitted,
} from '@helios/shared/analytics/segment'
import Button from '@helios/shared/components/button'
import {GlobalText} from '@helios/shared/components/global-text'
import Image from '@helios/shared/components/image'
import Input from '@helios/shared/components/input'
import type {Props as ModalProps} from '@helios/shared/components/modal'
import {Modal, ModalContent, ModalActions} from '@helios/shared/components/modal'
import {Text, text} from '@helios/shared/components/text'
import {useLocalizedContent} from '@helios/shared/hooks/use-localized-content'
import checkIconPink from '@helios/shared/images/icons/check-pink.svg'
import {useGlobalStrings} from '@helios/shared/providers/global-strings'
import type {FormConfig} from '@helios/shared/types/consents'
import getEnvironmentField from '@helios/shared/utils/get-environment-field'

export interface Props extends Omit<ModalProps, 'children'> {
    signupProps?: Args
    formConfig?: FormConfig
    highlightedFeatures?: string[]
    onSignup?: (value: boolean) => void
}

const getContent = (
    overrideDefaultContent: Partial<Props>,
    defaultContent: DefaultSignupModalQuery['allPrismicSignupmodal']['nodes'][0]['data'],
) => ({
    ...defaultContent.signup_modal[0],
    title: overrideDefaultContent.title || defaultContent.signup_modal[0].title,
    subtitle: overrideDefaultContent.subtitle || defaultContent.signup_modal[0].subtitle,
    image: overrideDefaultContent.image || {
        url: defaultContent.signup_modal[0].image.fluid.base64,
        dimensions: {
            width: 80,
            height: 80,
        },
        copyright: null,
        alt: null,
    },
    formConfig:
        overrideDefaultContent.formConfig ||
        getEnvironmentField({
            production: defaultContent.form_production,
            staging: defaultContent.form_staging,
        }),
    highlightedFeatures:
        overrideDefaultContent.highlightedFeatures ||
        [
            defaultContent.signup_modal[0].highlighted_feature_1,
            defaultContent.signup_modal[0].highlighted_feature_2,
            defaultContent.signup_modal[0].highlighted_feature_3,
        ].filter((item) => Boolean(item)),
})

export const SignupModal = ({
    isOpen,
    onDismiss,
    onSignup,
    title,
    subtitle,
    image,
    formConfig,
    signupProps = {form: 'Front'},
    highlightedFeatures,
}: Partial<Props>) => {
    const {allPrismicSignupmodal} = useStaticQuery<DefaultSignupModalQuery>(query)
    const [defaultContent] = useLocalizedContent(allPrismicSignupmodal.nodes)
    const overrideContent = {
        title,
        subtitle,
        image,
        formConfig,
        highlightedFeatures,
    }
    const defaultContentTransformed = {
        ...defaultContent.data,
        signup_modal: [
            {
                ...defaultContent.data.signup_modal[0],
                title: signupProps.isPartner
                    ? defaultContent.data.signup_modal[0].partner_title
                    : defaultContent.data.signup_modal[0].title,
                subtitle: signupProps.isPartner
                    ? defaultContent.data.signup_modal[0].partner_subtitle
                    : defaultContent.data.signup_modal[0].subtitle,
            },
        ],
    }
    const content = getContent(overrideContent, defaultContentTransformed)

    const globalStrings = useGlobalStrings()
    const {handleSubmit, clearError, setEmail, email, loading, error, handleEmailChange} =
        useSignup(signupProps, content.formConfig.formId)
    const errorMessage = useEmailValidationErrorMessage(error)

    const showReviewQuote = hasCookieExperiment({
        experimentName: 'social-signup-modal',
        bucketName: 'reviewQuote',
    })

    const showRatingsAndBadges = hasCookieExperiment({
        experimentName: 'social-signup-modal',
        bucketName: 'ratingsAndBadges',
    })

    const showControl =
        signupProps.form === 'Pricing' ||
        signupProps.isPartner ||
        (!showReviewQuote && !showRatingsAndBadges) ||
        !content.social_proof_subtitle ||
        !content.review_quote_title

    const handleDismiss = () => {
        clearError()
        setEmail('')
        onDismiss && onDismiss()
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        signupFormSubmitted({marketingConsent: false, privacyConsent: false})
        handleSubmit(event)
    }

    const hasHighlightedFeatures =
        content.highlightedFeatures && content.highlightedFeatures.length > 0

    React.useEffect(() => {
        if (isOpen) {
            signupFormModalOpened({action: SignupFormModalOpenedAction.Opened})
        }
    }, [isOpen])

    React.useEffect(() => {
        if (onSignup) {
            onSignup(loading)
        }
    }, [loading, onSignup])

    const getSubtitle = React.useMemo(() => {
        if (showControl) {
            return content.subtitle
        }

        if (showRatingsAndBadges) {
            return (
                <>
                    <RatingAndBadges>
                        <Image image={content.social_proof_logo_1} />
                        <Image image={content.social_proof_logo_2} />
                        <Image image={content.social_proof_logo_3} />
                    </RatingAndBadges>
                    <Text
                        $variant="label-base-medium"
                        $center
                        style={{marginTop: tokens.spacing20}}
                    >
                        {content.social_proof_subtitle}
                    </Text>
                </>
            )
        }

        if (showReviewQuote) {
            return (
                <ReviewQuote>
                    <Image image={content.review_quote_logo} />
                    <div>
                        <Text $variant="paragraph-base">{content.review_quote_title}</Text>
                        <cite>
                            <Text as="span" $variant="paragraph-base">
                                {content.review_quote_author}
                            </Text>
                            <Text
                                $variant="label-base-medium"
                                style={{marginTop: tokens.spacing12}}
                            >
                                {content.review_quote_subtitle}
                            </Text>
                        </cite>
                    </div>
                </ReviewQuote>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showControl, showRatingsAndBadges, showReviewQuote, content.subtitle])

    return (
        <Modal
            title={content.title}
            subtitle={getSubtitle}
            image={showControl ? content.image : undefined}
            isOpen={isOpen}
            onDismiss={handleDismiss}
        >
            <form onSubmit={onSubmit} noValidate>
                <ModalContent>
                    {hasHighlightedFeatures && (
                        <FeaturesList>
                            {content.highlightedFeatures.map((feature, idx) => (
                                <Item key={idx}>{feature}</Item>
                            ))}
                        </FeaturesList>
                    )}

                    <Input
                        type="email"
                        label={globalStrings.work_email}
                        value={email}
                        onChange={(value) => {
                            handleEmailChange({
                                target: {value},
                            } as React.ChangeEvent<HTMLInputElement>)
                        }}
                        error={errorMessage}
                        autoFocus
                        data-testid="modal-input"
                    />
                </ModalContent>

                <ModalActions>
                    <Button type="submit" $loading={loading} data-testid="modal-submit-button">
                        {globalStrings.submit}
                    </Button>
                </ModalActions>
                <Text
                    forwardedAs={StyledGlobalText}
                    globalStringKey="financial_partner_disclaimer"
                    $variant="label-base"
                />
            </form>
        </Modal>
    )
}

const FeaturesList = styled(CheckmarkList)`
    margin: 0;
    gap: ${tokens.spacing4};
`

const Item = styled.li`
    ${text({$variant: 'paragraph-base'})}
    color: ${colors.text.muted};

    &::before {
        padding-right: ${tokens.spacing12};
        content: url(${checkIconPink});
    }
`

const StyledGlobalText = styled(GlobalText)`
    margin-top: ${tokens.spacing12};
`

const RatingAndBadges = styled.div`
    margin-top: ${tokens.spacing20};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${tokens.spacing20};
    padding-left: ${tokens.spacing16};
    padding-right: ${tokens.spacing16};
`

const ReviewQuote = styled.blockquote`
    margin-top: ${tokens.spacing20};
    display: grid;
    grid-template-columns: 40px auto;
    gap: ${tokens.spacing20};
`

const query = graphql`
    query DefaultSignupModal {
        allPrismicSignupmodal {
            nodes {
                lang
                data {
                    form_production {
                        formId
                        consents {
                            type
                            communicationConsents {
                                id
                                required
                            }
                        }
                    }
                    form_staging {
                        formId
                        consents {
                            type
                            communicationConsents {
                                id
                                required
                            }
                        }
                    }
                    signup_modal {
                        image {
                            fluid {
                                base64
                            }
                        }
                        highlighted_feature_1
                        highlighted_feature_2
                        highlighted_feature_3
                        title
                        subtitle
                        partner_title
                        partner_subtitle
                        review_quote_title
                        review_quote_author
                        review_quote_subtitle
                        review_quote_logo {
                            dimensions {
                                height
                                width
                            }
                            alt
                            copyright
                            url
                            gatsbyImageData(breakpoints: [40, 80, 120], sizes: "40px")
                        }
                        social_proof_logo_1 {
                            dimensions {
                                height
                                width
                            }
                            alt
                            copyright
                            url
                            gatsbyImageData(
                                breakpoints: [136, 272, 408]
                                sizes: "(max-width: 570px) calc((100% - 48px) / 3), 136px"
                            )
                        }
                        social_proof_logo_2 {
                            dimensions {
                                height
                                width
                            }
                            alt
                            copyright
                            url
                            gatsbyImageData(
                                breakpoints: [136, 272, 408]
                                sizes: "(max-width: 570px) calc((100% - 48px) / 3), 136px"
                            )
                        }
                        social_proof_logo_3 {
                            dimensions {
                                height
                                width
                            }
                            alt
                            copyright
                            url
                            gatsbyImageData(
                                breakpoints: [136, 272, 408]
                                sizes: "(max-width: 570px) calc((100% - 48px) / 3), 136px"
                            )
                        }
                        social_proof_subtitle
                    }
                }
            }
        }
    }
`
