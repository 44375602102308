import {getExperiments} from '@helios/shared/utils/get-experiments'

interface Props {
    experimentName: string
    bucketName: string
}

/**
 * Checks if the client is in a given bucket in a given experiment.
 *
 * @param experimentName Experiment name input from the experiments ui
 * @param  BucketName Bucket name input from the experiments ui
 * @returns boolean
 */
const hasCookieExperiment = ({experimentName, bucketName}: Props) => {
    const experiments = getExperiments()

    return experiments[experimentName] === bucketName
}

export default hasCookieExperiment
