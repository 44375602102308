import type {LocaleCode} from '../../../shared/locale/locales'
import type {LinkResolver} from '../../../shared/types/link-resolver'
import localeCodeToUrl from '../../../shared/utils/locale-to-url'

export const linkResolver: LinkResolver = (doc) => {
    const localize = (path: string) =>
        `/${localeCodeToUrl(doc.lang as LocaleCode)}${path === '/' ? '' : path}`

    switch (doc.type) {
        case 'blog_homepage':
            return localize('/')

        case 'blog_author':
            return localize(`/author/${doc.uid}`)

        case 'blog_category':
            return localize(`/category/${doc.uid}`)

        case 'blog_tag':
            return localize(`/tag/${doc.uid}`)

        case 'blog_confirm_newsletter_signup_page':
            return localize('/confirm-newsletter-signup')

        case 'blog_post':
            return localize(`/${doc.uid}`)

        case 'blog_not_found_page':
            return localize('/404')

        default:
            return 'UNKNOWN_DOCUMENT_TYPE'
    }
}
