import tokens from '@pleo-io/telescope-tokens'
import {keyframes} from 'styled-components'

export {tokens}

export const colors = {
    text: {
        default: tokens.shade900,
        muted: tokens.shade700,
        lightMuted: tokens.shade600,
        extraMuted: tokens.shade500,
        placeholder: tokens.shade600,
        inverted: tokens.shade000,
        brand: tokens.pink800,
        error: tokens.red600,
    },
    link: {
        pink: {
            foreground: tokens.shade900,
            background: 'transparent',
            hover: {
                foreground: tokens.shade900,
                background: tokens.pink300,
            },
        },
        black: {
            foreground: tokens.shade900,
            background: 'transparent',
            hover: {
                foreground: tokens.shade000,
                background: tokens.shade900,
            },
        },
        lightMuted: {
            foreground: tokens.shade600,
            background: 'transparent',
            hover: {
                foreground: tokens.shade900,
                background: tokens.pink300,
            },
        },
    },
    button: {
        disabled: {
            background: tokens.shade300,
            foreground: tokens.shade500,
            border: tokens.shade300,
        },
        default: {
            foreground: tokens.shade000,
            background: tokens.shade800,
            border: tokens.shade800,
            hover: {
                background: tokens.shade900,
                foreground: tokens.shade000,
                border: tokens.shade900,
            },
        },
        outline: {
            foreground: tokens.shade900,
            background: 'transparent',
            border: tokens.shade900,
            hover: {
                background: tokens.shade900,
                foreground: tokens.shade000,
                border: tokens.shade900,
            },
        },
        inverted: {
            foreground: tokens.shade800,
            background: tokens.shade000,
            border: tokens.shade000,
            hover: {
                background: tokens.shade300,
                foreground: tokens.shade900,
                border: tokens.shade100,
            },
        },
        'inverted-outline': {
            foreground: tokens.shade000,
            background: 'transparent',
            border: tokens.shade000,
            hover: {
                background: tokens.shade000,
                foreground: tokens.shade900,
                border: tokens.shade000,
            },
        },
    },
    border: {
        default: tokens.shade300,
        secondary: tokens.shade900,
        dark: tokens.shade400,
        inverted: tokens.shade000,
        brand: tokens.pink700,
        error: tokens.red600,
    },
    background: {
        default: tokens.shade000,
        extraMuted: tokens.shade100,
        muted: tokens.shade200,
        secondary: tokens.shade300,
        tertiary: tokens.shade900,
        yellow: tokens.yellow400,
        pink: tokens.pink300,
        purple: tokens.purple300,
        blue: tokens.blue300,
        green: tokens.green300,
        darkGrey: tokens.shade800,
        grey: tokens.shade200,
    },
    tag: {
        grey: tokens.shade300,
        yellow: tokens.yellow400,
        pink: tokens.pink300,
        purple: tokens.purple300,
        blue: tokens.blue300,
        green: tokens.green300,
        red: tokens.red300,
    },
    overlay: {
        light: 'rgba(255, 255, 255, 0.8)',
    },
    banner: {
        background: tokens.pink400,
    },
    textInput: {
        disabled: {
            foreground: tokens.shade500,
            background: tokens.shade100,
            border: 'transparent',
        },
        default: {
            foreground: tokens.shade900,
            background: tokens.shade200,
            border: 'transparent',
        },
        signup: {
            foreground: tokens.shade900,
            background: tokens.shade000,
            border: tokens.shade500,
        },
    },
    focusRing: tokens.blue700,
}

const getFontSizeRange = (index: number) => {
    const scale = 1.414
    const baseSizeMin = 13
    const baseSizeMax = 16
    const multiplier = Math.pow(scale, index)
    const min = baseSizeMin * multiplier
    const max = baseSizeMax * multiplier

    return [Math.round(min), Math.round(max)]
}

export const fontPrimary = `'Neue Haas Grotesk Display'`
export const fontSecondary = `'Neue Haas Grotesk Text'`
export const fontTertiary = `'Spezia Monospace SemiMono'`
export const fontFallback = `system-ui, 'Helvetica Neue', 'Arial', sans-serif`

export const fonts = {
    families: {
        primary: `${fontPrimary}, ${fontFallback}`,
        secondary: `${fontSecondary}, ${fontFallback}`,
        tertiary: `${fontTertiary}, monospace, 'Helvetica Neue', 'Arial', sans-serif`,
    },
    sizes: {
        xxs: [13, 13],
        xs: getFontSizeRange(0),
        sm: getFontSizeRange(1),
        md: getFontSizeRange(2),
        lg: getFontSizeRange(3),
        xl: getFontSizeRange(4),
        xxl: getFontSizeRange(5),
    },
    lineHeights: {
        xxs: 1.5,
        xs: 1.5,
        sm: 1.6,
        md: 1.4,
        lg: 1.2,
        xl: 1.1,
        xxl: 1,
    },
    weights: {
        normal: 400,
        semiBold: 600,
    },
}

export const textVariants = {
    'label-base': {
        fontFamily: fonts.families.secondary,
        fontSize: 'xxs' as const,
        lineHeight: fonts.lineHeights.xxs,
        fontWeight: fonts.weights.normal,
    },
    'label-base-medium': {
        fontFamily: fonts.families.secondary,
        fontSize: 'xxs' as const,
        lineHeight: fonts.lineHeights.xxs,
        fontWeight: fonts.weights.semiBold,
    },
    'paragraph-base-primary': {
        fontFamily: fonts.families.primary,
        fontSize: 'xs' as const,
        lineHeight: fonts.lineHeights.xs,
        fontWeight: fonts.weights.normal,
    },
    'paragraph-base': {
        fontFamily: fonts.families.secondary,
        fontSize: 'xs' as const,
        lineHeight: fonts.lineHeights.xs,
        fontWeight: fonts.weights.normal,
    },
    'paragraph-large': {
        fontFamily: fonts.families.primary,
        fontSize: 'sm' as const,
        lineHeight: fonts.lineHeights.sm,
        fontWeight: fonts.weights.normal,
    },
    'paragraph-large-medium': {
        fontFamily: fonts.families.primary,
        fontSize: 'sm' as const,
        lineHeight: fonts.lineHeights.sm,
        fontWeight: fonts.weights.semiBold,
    },
    'title-base': {
        fontFamily: fonts.families.primary,
        fontSize: 'md' as const,
        lineHeight: fonts.lineHeights.md,
        fontWeight: fonts.weights.normal,
    },
    'heading-base': {
        fontFamily: fonts.families.primary,
        fontSize: 'lg' as const,
        lineHeight: fonts.lineHeights.lg,
        fontWeight: fonts.weights.normal,
    },
    'heading-large': {
        fontFamily: fonts.families.primary,
        fontSize: 'xl' as const,
        lineHeight: fonts.lineHeights.xl,
        fontWeight: fonts.weights.normal,
    },
    hero: {
        fontFamily: fonts.families.primary,
        fontSize: 'xxl' as const,
        lineHeight: fonts.lineHeights.xxl,
        fontWeight: fonts.weights.normal,
    },
    spezia: {
        fontFamily: fonts.families.tertiary,
        fontSize: 'xs' as const,
        lineHeight: fonts.lineHeights.xs,
        fontWeight: fonts.weights.normal,
    },
}

export const breakpoints = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1440,
}

export const spacing = {
    xs: [24, 32],
    sm: [48, 64],
    md: [64, 86],
    lg: [112, 174],
    xl: [164, 324],
}

export const zIndex = {
    signupBackground: -1,
    observerElement: -1,
    backToTopButton: 1,
    phoneInputCountrySelect: 1,
    feedbackButton: 1,
    cookieBanner: 2,
    chatbot: 3,
    header: 3,
    menuOverlay: 3,
    headerLogo: 4,
    notificationBanner: 4,
    modalOverlay: 4,
    videoPopover: 6,
    cookieBanner2: 7, // TODO: Unify with cookieBanner
}

export const radii = {
    arc2: '2px',
    arc4: tokens.arc4,
    arc8: tokens.arc8,
    arc20: tokens.arc20,
    circle: '9999em',
}

export const animations = {
    fadeIn: keyframes`
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
`,
}

export const headerSizes = {
    heightSmall: 78,
    heightLarge: 83,
    breakpoint: 'lg' as const,
}

export const focusRingSizes = {
    thin: '2px',
    thick: '4px',
}

export type ImageLayoutSizesType = {
    twoColumns?: string
    twoColumnsAsymmetric?: string
    threeColumns?: string
    fourColumns?: string
}

/*
 * We set the image sizes we need to pass to gatsbyImageData queries
 * in relation to the layout the images are presented.
 * We use calc for removing the side padding and grid gap and
 * calculate the maximum image width for each media query.
 */
export const imageLayoutSizes: ImageLayoutSizesType = {
    twoColumns: `(max-width: ${breakpoints.sm - 1}px) calc(100vw - 2 * ${
        tokens.spacing24
    }), (max-width: ${breakpoints.md - 1}px) calc(50vw - 2 * ${tokens.spacing24}), (max-width: ${
        breakpoints.xl - 1
    }px) calc(50vw - 2 * ${tokens.spacing40}), 600px`,
    twoColumnsAsymmetric: `(max-width: ${breakpoints.sm - 1}px) calc(100vw - 2 * ${
        tokens.spacing24
    }), (max-width: ${breakpoints.md - 1}px) calc(40vw - 3 * ${tokens.spacing24}), (max-width: ${
        breakpoints.xl - 1
    }px) calc(55vw - 3 * ${tokens.spacing40}), 600px`,
    threeColumns: `(max-width: ${breakpoints.xs - 1}px) calc(100vw - 2 * ${
        tokens.spacing24
    }), (max-width: ${breakpoints.sm - 1}px) calc(50vw - 1.5 * ${tokens.spacing24}), (max-width: ${
        breakpoints.md - 1
    }px) calc(33.33vw - 1.5 * ${tokens.spacing24} - 2 * ${tokens.spacing20}), (max-width: ${
        breakpoints.xl - 1
    }px) calc(25vw - 1.5 * ${tokens.spacing40} - 2 * ${tokens.spacing20}), 255px`,
    fourColumns: `(max-width: ${breakpoints.xs - 1}px) calc(100vw - 2 * ${tokens.spacing24} - 2 * ${
        tokens.spacing20
    }), (max-width: ${breakpoints.sm - 1}px) calc(50vw - 1.5 * ${tokens.spacing24} - 2 * ${
        tokens.spacing20
    }), (max-width: ${breakpoints.md - 1}px) calc(33.33vw - 1.5 * ${tokens.spacing24} - 2 * ${
        tokens.spacing20
    }), (max-width: ${breakpoints.xl - 1}px) calc(25vw - 1.5 * ${tokens.spacing40} - 2 * ${
        tokens.spacing20
    }), 255px`,
}

/*
 * These are the images that will be created on build.
 * Each number represents the created image width.
 * Breakpoints above the the original image's width will be skipped.
 */
export const imageBreakpoints = [720, 940, 1080, 1440]
