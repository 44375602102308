import type React from 'react'

import {ErrorType} from '@/hooks/use-signup'
import {useGlobalStrings} from '@helios/shared/providers/global-strings'

type ErrorMap = Record<string, string | React.ReactNode>

export const useEmailValidationErrorMessage = (error: Error | null) => {
    const globalStrings = useGlobalStrings()

    if (!error) {
        return null
    }

    const errorMap: ErrorMap = {
        [ErrorType.INVALID_EMAIL]: globalStrings.invalid_email_error,
        [ErrorType.NON_WORK_EMAIL]: globalStrings.non_work_email_error,
    }

    return errorMap[error.message || ErrorType.INVALID_EMAIL]
}
