import Cookies from 'js-cookie'

import {cookiesMap} from '@helios/shared/config/cookies'

const googleAdsCookies = [
    cookiesMap.adStorage,
    cookiesMap.adUserData,
    cookiesMap.adPersonalization,
    cookiesMap.analyticsStorage,
] as const

type CookieName = (typeof googleAdsCookies)[number]

export enum ConsentState {
    Denied = 'denied',
    Granted = 'granted',
}

export const verifyInitialConsentsState = () => {
    const consentsPayload: {[K in CookieName]?: string} = {}

    analytics.ready(() => {
        if (typeof window.gtag !== 'undefined') {
            window.gtag('consent', 'default', {
                ad_storage: ConsentState.Denied,
                ad_user_data: ConsentState.Denied,
                ad_personalization: ConsentState.Denied,
                analytics_storage: ConsentState.Denied,
            })
        }
    })

    googleAdsCookies.forEach((cookie) => {
        if (Cookies.get(cookie) === undefined) {
            Cookies.set(cookie, ConsentState.Denied, {
                expires: 60,
                ...(window.location.hostname !== 'localhost' && {domain: '.pleo.io'}),
            })
            consentsPayload[cookie] = ConsentState.Denied
        }

        consentsPayload[cookie] = Cookies.get(cookie)
    })

    analytics.ready(() => {
        window.gtag('consent', 'update', consentsPayload)
    })
}

export const updateConsentsState = (state: ConsentState) => {
    analytics.ready(() => {
        if (typeof window.gtag !== 'undefined') {
            window.gtag('consent', 'update', {
                ad_storage: state,
                ad_user_data: state,
                ad_personalization: state,
                analytics_storage: state,
            })
        }
    })

    googleAdsCookies.forEach((cookie) => {
        Cookies.set(cookie, state, {
            expires: 60,
            ...(window.location.hostname !== 'localhost' && {domain: '.pleo.io'}),
        })
    })
}
