import type {LocaleCode} from '@helios/shared/locale/locales'

const localeCodeToUrl = (localeCode: LocaleCode) => {
    const [language, country] = localeCode.toLowerCase().split('-')

    switch (localeCode) {
        case 'en-gb':
        case 'da-dk':
            return language

        case 'fr-be':
        case 'nl-be':
            return localeCode

        default: {
            return country
        }
    }
}

export default localeCodeToUrl
