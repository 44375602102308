import {useGoogleReCaptcha} from '@helios/shared/providers/google-recaptcha'

// The possible actions used for grouping captcha results
export type CaptchaAction =
    | 'hubspot_submit_form'
    | 'email_subscription'
    | 'job_application_form'
    | 'contact_form'

/**
 * Make sure that the components that call this are wrapped into GoogleReCaptchaWrapper
 * (in providers directory) otherwise the request will fail.
 *
 * @param action Optional string that identifies the action protected by the captcha.
 * In Google's interface, we can see captcha results grouped by this action
 * string.
 */
export const useRecaptcha = (action?: CaptchaAction) => {
    const {executeRecaptcha} = useGoogleReCaptcha()

    if (!executeRecaptcha) {
        return undefined
    }

    const protectedCall = async function <Response>(callback: (token: string) => Response) {
        const token = await executeRecaptcha(action)

        return callback(token)
    }

    return {protectedCall}
}
