import {zIndex} from '@commercial-helios/ui/theme'
import {ConsentManagerBuilder, savePreferences} from '@segment/consent-manager'
import React from 'react'
import styled from 'styled-components'

import {isBrowser} from '@helios/shared/utils/browser'
import mq from '@helios/shared/utils/media-query'

import type {CookieBannerContent} from './cookie-banner'
import {CookieBanner} from './cookie-banner'

interface Props {
    content: CookieBannerContent
}

const FooterBannerWrapper = ({content}: Props) => {
    const [showFallbackBanner, setShowFallbackBanner] = React.useState(false)

    // If Segment fails to load, we still show the cookie banner and allow
    // to save the preferences in a cookie
    if (showFallbackBanner) {
        return (
            <Container>
                <CookieBanner
                    content={content}
                    saveConsent={() => {}}
                    setPreferences={(preferences) =>
                        savePreferences({destinationPreferences: preferences})
                    }
                    newDestinations={[]}
                />
            </Container>
        )
    }

    return (
        <Container>
            <ConsentManagerBuilder
                writeKey={process.env.GATSBY_SEGMENT_WRITE_KEY!}
                shouldRequireConsent={() =>
                    (isBrowser && window._DATADOG_SYNTHETICS_BROWSER) || false
                }
                onError={() => setShowFallbackBanner(true)}
            >
                {(consentProps) => <CookieBanner content={content} {...consentProps} />}
            </ConsentManagerBuilder>
        </Container>
    )
}

const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: ${zIndex.cookieBanner};
    display: flex;
    width: 100%;
    pointer-events: none;
    flex-direction: column;
    transform: translateX(-50%);

    ${mq.min('sm')} {
        justify-content: flex-start;
    }
`

export default FooterBannerWrapper
