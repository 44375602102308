import {colors, fonts, textVariants, tokens} from '@commercial-helios/ui/theme'
import React from 'react'
import styled, {css} from 'styled-components'

import {focusStyles} from '@helios/shared/styles/utils'
import type {FontColor} from '@helios/website/src/utils/is-valid-font-color'

import {text} from './text'
import type {TextProps} from './text'

export interface TextLinkProps extends Omit<React.HTMLProps<HTMLAnchorElement>, 'data'>, TextProps {
    $bgColor?: keyof typeof colors.link
    as?: any
}

const StyledTextLink = React.forwardRef<HTMLAnchorElement, TextLinkProps>(
    ({as: TextLinkComponent = 'a', forwardedAs, id, children, ...props}, ref) => {
        const primaryFontVariants = Object.keys(textVariants).filter(
            (variant) =>
                textVariants[variant as NonNullable<TextProps['$variant']>].fontFamily ===
                fonts.families.primary,
        )

        const primaryFont = 'primary'

        const isPrimaryVariant = !props.$variant || primaryFontVariants.includes(props.$variant)

        return (
            <TextLinkComponent
                {...props}
                id={id}
                ref={ref}
                as={forwardedAs}
                {...(isPrimaryVariant && {'data-font': primaryFont})}
            >
                {children}
            </TextLinkComponent>
        )
    },
)

export const TextLink = styled(StyledTextLink)<TextLinkProps>(
    ({$bgColor = 'pink', $upperCase, $center, $variant, $color}) => css`
        ${text({$color: $color, $upperCase: $upperCase, $center: $center, $variant: $variant})}

        padding: 0;
        color: ${colors.link[$bgColor].foreground};
        text-align: inherit;
        text-decoration: underline;
        cursor: pointer;
        background: ${colors.link[$bgColor].background};
        border: none;
        transition: background ${tokens.fastIn};

        &:hover,
        &:active {
            color: ${colors.link[$bgColor].hover.foreground};
            background: ${colors.link[$bgColor].hover.background};
        }

        &:focus {
            ${focusStyles()}
            border-radius: ${tokens.arc4};
        }
    `,
)

type NavTextLinkProps = {$color?: FontColor}

export const NavTextLink = styled(TextLink)<NavTextLinkProps>`
    color: ${(props) => (props.$color ? colors.text[props.$color] : colors.link.pink.foreground)};
    text-decoration: none;
    background: ${colors.link.pink.background};

    &:hover,
    &:active {
        color: ${colors.link.pink.foreground};
        text-decoration: underline;
        background: ${colors.link.pink.background};
    }

    &:focus {
        ${focusStyles()}
        border-radius: ${tokens.arc4};
    }
`
