import {pageTranslated, PageTranslatedAction} from '@helios/shared/analytics/segment'

export const detectPageTranslation = () => {
    const target = document.querySelector('html')
    const observer = new MutationObserver(mutate)

    if (target) {
        observer.observe(target, config)
    }
}

const mutate: MutationCallback = (mutations) => {
    mutations.forEach((mutation) => {
        const target = document.documentElement

        if (
            mutation.attributeName === 'lang' &&
            mutation.oldValue !== target.lang &&
            mutation.oldValue !== 'auto' &&
            Array.from(target.classList).some((className) =>
                ['translated-ltr', 'translated-rtl'].includes(className),
            )
        ) {
            const parsedUrl = new URL(window.location.href)

            pageTranslated({
                action: PageTranslatedAction.PageTranslated,
                language: target.lang,
                path: parsedUrl.pathname,
            })
        }
    })
}

const config = {
    attributeFilter: ['class', 'lang'],
    attributeOldValue: true,
}
