import type {ReactNode} from 'react'
import React from 'react'

import type {GlobalStrings} from '@helios/shared/types/global-strings'

const GlobalStringsContext = React.createContext<GlobalStrings>({
    notification_banner_message: '',
    close_button_text: '',
    visit_website: '',
    read_more: '',
    learn_more: '',
    show_more: '',
    watch_now: '',
    recaptcha_and_privacy_terms: '',
    show_me: '',
    all: '',
    copied_to_clipboard: '',
    something_went_wrong: '',
    first_name: '',
    last_name: '',
    work_email: '',
    company_name: '',
    company_size: '',
    select: '',
    phone_number: '',
    submit: '',
    accept_terms_and_privacy: '',
    this_field_is_required: '',
    must_be_company_email: '',
    sole_trader: '',
    employee: '',
    employees: '',
    your_full_name: '',
    your_work_email: '',
    please_select_number_of_employees: '',
    please_enter_your_work_email: '',
    please_enter_a_work_email: '',
    sticky_cta_button_text: '',
    play: '',
    admin: '',
    industry: '',
    location: '',
    days: '',
    hours: '',
    minutes: '',
    back: '',
    home: '',
    menu: '',
    invalid_email_error: '',
    loading: '',
    preview: '',
    clearbitCredits: '',
    playVideo: '',
    mins_read: '',
    min_read: '',
    latest_articles: '',
    tags: '',
    panel: '',
    related_posts_text: '',
    sequence_posts_text: '',
    non_work_email_error_cta_text: '',
    non_work_email_error: '',
    country: '',
    localeSelectorGlobalLabel: '',
    marketingEmailsConsentText: '',
    yourEmailLabel: '',
    signUp: '',
    skipToContent: '',
    consent_label: '',
    country_placeholder: '',
    discovered_via_label: '',
    discovered_via_placeholder: '',
    demo_submit_text: '',
    other: '',
    peer_recommendation: '',
    trust_pilot: '',
    google_search: '',
    youtube: '',
    tv: '',
    outdoor_advertising: '',
    social_media: '',
    press: '',
    first_name_error: '',
    last_name_error: '',
    phone_number_error: '',
    company_size_error: '',
    country_error: '',
    has_consented_error: '',
    get_started: '',
    watchTheVideo: '',
    file_upload_too_large: '',
    file_type: '',
    upload_text: '',
    your_message: '',
    send_message: '',
    form_submission_error: '',
    num_customers: '',
    consents_error: '',
    feedback: '',
    interest_message_label: '',
    financial_partner_disclaimer: '',
    privacyConsentText: '',
    contact_sales: '',
    back_to_pleo_io: '',
    email: '',
    address: '',
    city: '',
    postcode: '',
    state: '',
    invoice_number: '',
    issued_on: '',
    due_date: '',
    company_logo: '',
    currency: '',
})

type Props = {
    content: GlobalStrings
    children: ReactNode
}

export const GlobalStringsProvider = ({children, content}: Props) => (
    <GlobalStringsContext.Provider value={content}>{children}</GlobalStringsContext.Provider>
)

export const useGlobalStrings = () => React.useContext(GlobalStringsContext)
