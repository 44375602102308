import {breakpoints} from '@commercial-helios/ui/theme'

export type Breakpoints = typeof breakpoints

const mq = ((breakpoints: Breakpoints) => {
    const min = (bp: keyof Breakpoints): string => {
        const mediaQuery = `@media (min-width: ${breakpoints[bp]}px)`
        return mediaQuery.trim()
    }

    const max = (bp: keyof Breakpoints): string => {
        const mediaQuery = `@media (max-width: ${breakpoints[bp] - 1}px)`
        return mediaQuery.trim()
    }

    const between = (bp1: keyof Breakpoints, bp2: keyof Breakpoints): string => {
        const mediaQuery = `
            @media (min-width: ${breakpoints[bp1]}px) and (max-width: ${breakpoints[bp2] - 1}px)
        `
        return mediaQuery.trim()
    }

    return {min, max, between}
})(breakpoints)

export default mq
