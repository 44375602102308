import Cookies from 'js-cookie'

import {cookiesMap} from '../../../shared/config/cookies'

// After the user is inactive for this amount of time, we consider them to be in a new session
const SESSION_LENGTH_IN_MINUTES = 30

export const sessionTrackingProperties = () => {
    const hasCurrentSessionCookie = Cookies.get(cookiesMap.sessionStatusKey) === 'true'
    const hasPreviousSessionCookie = Cookies.get(cookiesMap.previousSessionStatusKey) === 'true'
    const storedLandingPage = Cookies.get(cookiesMap.landingPageKey)
    const storedRawLandingPage = Cookies.get(cookiesMap.rawLandingPageKey)
    const storedLandingLocale = Cookies.get(cookiesMap.landingLocale)

    const host = window.location.host
    const pathname = window.location.pathname

    const [locale, ...rawPathParts] = pathname.replace(/^\//, '').split('/')
    const rawPath = rawPathParts.length ? rawPathParts.join('/') : '/'

    // If the cookie exists and hasn't expired, keep the same value.
    // Otherwise, use the path of the current page
    const landingPage = storedLandingPage || host + pathname
    const rawLandingPage = storedRawLandingPage || rawPath
    const landingLocale = storedLandingLocale || locale

    return {
        landingPage,
        rawLandingPage,
        landingLocale,
        loggedInCurrently: hasCurrentSessionCookie,
        loggedInPreviously: hasPreviousSessionCookie || hasCurrentSessionCookie,
        facebookClickId: Cookies.get(cookiesMap.metaClickId),
    }
}

export const updateSessionTrackingCookies = () => {
    const {landingPage, rawLandingPage, landingLocale, loggedInCurrently} =
        sessionTrackingProperties()
    const expires = new Date()

    // Set the cookie expiration as `SESSION_LENGTH_IN_MINUTES` from now
    expires.setMinutes(expires.getMinutes() + SESSION_LENGTH_IN_MINUTES)

    const cookiesToSet = [
        {key: cookiesMap.landingPageKey, value: landingPage, domain: 'pleo.io'},
        {key: cookiesMap.rawLandingPageKey, value: rawLandingPage, domain: 'pleo.io'},
        {key: cookiesMap.landingLocale, value: landingLocale},
    ]

    cookiesToSet.forEach(({key, value, domain}) => {
        Cookies.set(key, value, {
            // Reset the session timer on every page load
            expires,
            domain,
        })
    })

    if (loggedInCurrently) {
        Cookies.set(cookiesMap.previousSessionStatusKey, 'true', {expires: 365})
    }
}
